module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#22282C","theme_color":"#22282C","display":"minimal-ui","icon":"src/images/DA.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4b86f086aa0aa1a3a8c54dd7dfac8aa3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
